<template>
  <div>
    <!--Title Bar-->
    <title-bar
      v-if="section === 1 && usersStore.adminMode !== 1"
      :title-value="'Users'"
    />

    <!-- Filter -->
    <v-toolbar flat dense color="accent">
      <!--  Filter Icon -->
      <v-menu
        v-model="filterMenu"
        offset-y
        right
        :close-on-content-click="false"
      >
        <template #activator="{ on: menu }">
          <v-tooltip right color="primary">
            <template #activator="{ on: tooltip }">
              <v-btn icon v-on="{ ...tooltip, ...menu }">
                <v-icon v-if="showFiltered()" color="primary">
                  fas fa-filter
                </v-icon>
                <v-icon v-else color="primary">
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
            <span>Filter</span>
          </v-tooltip>
        </template>

        <v-card width="400px">
          <v-card-title>
            <v-spacer />
            <v-btn
              v-if="showFiltered()"
              text
              rounded
              outlined
              block
              @click="clearFilter()"
            >
              <v-icon small left>
                fal fa-times </v-icon
              >Clear Filters
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <!--User ID-->
                <v-col cols="12" sm="12">
                  <v-text-field v-model="id" label="User ID" placeholder=" " />
                </v-col>
              </v-row>

              <v-row dense>
                <!--First Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="name_first"
                    label="First Name"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Last Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="name_last"
                    label="Last Name"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Email-->
                <v-col cols="12" sm="12">
                  <v-text-field v-model="email" label="Email" placeholder=" " />
                </v-col>
              </v-row>

              <v-row dense>
                <!--User Type-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    v-model="flag_user_type"
                    :items="valueListsStore.userTypes"
                    label="User Type"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Contact PI-->
                <v-col cols="12" sm="12">
                  <v-checkbox
                    v-model="flag_contact_pi"
                    label="Contact PI"
                    color="primary"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    v-model="flag_status"
                    :items="valueListsStore.accessStatus"
                    label="Site Access"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mx-4 pb-4">
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>
                fal fa-check </v-icon
              >Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <!--Pagination  -->
      <pagination
        :show="
          usersStore.users.data &&
          usersStore.users.data.length > 0 &&
          usersStore.users.last_page > 1
            ? true
            : false
        "
        :current-page="usersStore.users.current_page"
        :last-page="usersStore.users.last_page"
        @paginate-event="filter($event)"
      />

      <v-spacer />

      <user :section="section" />

      <!-- Delete Dialog -->
      <v-dialog v-model="deleteDialog" persistent max-width="500">
        <v-card flat>
          <v-card-title class="text-h5 secondary--text">
            Delete User Record?
            <v-spacer />
            <v-btn text rounded outlined @click="cancelDelete()">
              <v-icon small left>
                fal fa-times </v-icon
              >Cancel
            </v-btn>
          </v-card-title>
          <v-card-text class="text-center px-10 py-4 secondary--text">
            <v-row justify="center" dense>
              <v-col cols="12" sm="12">
                <span class="text-subtitle-1  secondary--text font-italic">
                  Are you sure you want to delete this User record?
                </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mx-4 pb-4">
            <v-row dense>
              <v-spacer />

              <v-col cols="12" sm="12">
                <v-btn block rounded color="red" dark @click="deleteRecord()">
                  <v-icon small left>
                    fal fa-trash-alt </v-icon
                  >Yes, Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="
          usersStore.users.data && usersStore.users.data.length > 0
            ? true
            : false
        "
        :list-data="usersStore.users.data"
        :title="'Users'"
        :section="section === 1 ? 5 : Number(5 + '.' + section)"
        :button-title="'User'"
        :actions="actions"
        @open-entry="openEntry($event)"
        @open-detail="openDetail($event)"
        @open-change-password="openChangePassword($event)"
        @open-email="openEmail($event)"
        @invite="$_invite($event)"
        @delete-entry="deleteEntry($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import User from "@/components/User";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";

const { mapFields } = createHelpers({
  getterType: "usersStore/getFilterField",
  mutationType: "usersStore/updateFilterField",
});

export default {
  name: "Users",
  components: {
    TitleBar,
    User,
    Pagination,
    ListBasic,
  },
  mixins: [mixin],
  props: {
    /*
     1 = Settings Users List,
     2 = RPPR List,
     3 = Project Detail Users List,
     4 = Trainees List (Researcher List Of Trainees),
     5 = Admin Researchers List,
     6 = Admin Trainees List,
     7 = Admin Others Project Users List,
     8 = Admin RPPR List,
     9 = Trainees Dashboard,
     10 = RPPR Researchers List,
     */
    section: Number,
  },
  data() {
    return {
      filterMenu: false,
      deleteDialog: false,
      actions: {
        menu: 1,
        view: 1,
        edit: 0,
        password: 1,
        invite: 1,
        email: 1,
        delete: 1,
      },
    };
  },
  computed: {
    ...mapState({
      progressStore: (state) => state.progressStore,
      usersStore: (state) => state.usersStore,
      participantsStore: (state) => state.participantsStore,
      valueListsStore: (state) => state.valueListsStore,
    }),
    ...mapFields([
      "page",
      "sectionStored",
      "id",
      "export",

      "activeUser",

      "id_project",
      "name_first",
      "name_last",
      "email",
      "flag_user_type",
      "flag_contact_pi",
      "flag_status",
    ]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);

      const activeUser = this.$store.getters["usersStore/getActiveUser"];

      this.export = 0;

      this.changeSectionCheck();

      /*If Settings Institutions section, make sure project ID is cleared */
      if (this.section === 1) {
        this.id_project = "";
      } else {
        let project = this.$store.getters["projectsStore/getProject"];
        this.id_project = project.id;
      }

      this.activeUser = activeUser.id;

      this.sectionStored = this.section;

      /* Grab filters */
      const filters = this.$store.getters["usersStore/getFilters"];
      /* Grab projects based on active filters */
      await this.$store.dispatch("usersStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.id = "";
      this.name_first = "";
      this.name_last = "";
      this.email = "";
      this.flag_user_type = "";
      this.flag_contact_pi = "";
      this.flag_status = "";
      this.email = "";
      this.page = 1;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    changeSectionCheck() {
      if (this.section !== this.sectionStored) {
        this.clearFilterColumns();
        this.$store.dispatch("usersStore/clearAllUserLists");
      }
    },

    async openEntry(id) {
      await this.$store.dispatch("usersStore/entry", id);
    },

    async switchUser(id) {
      this.$store.dispatch("progressStore/set", true);

      /*Clear stores used on dashboard*/
      this.$store.dispatch("fundingsStore/clearList");
      this.$store.dispatch("publicationsStore/clearList");
      this.$store.dispatch("patentsStore/clearList");
      this.$store.dispatch("presentationsStore/clearList");
      this.$store.dispatch("outcomesStore/clearList");
      this.$store.dispatch("fellowshipsStore/clearList");
      this.$store.dispatch("dissertationsStore/clearList");
      this.$store.dispatch("usersStore/clearTrainees");
      this.$store.dispatch("rpprStore/clearRppr");

      window.localStorage.setItem("adminMode", 1);
      this.$store.dispatch("usersStore/setAdminMode", 1);

      let userType;
      if (this.section === 4 || this.section === 6 || this.section === 9) {
        userType = [3];
      } else if (this.section === 5) {
        userType = [1, 2];
      } else {
        userType = "";
      }

      this.$store.dispatch("navigationStore/clearNavigation");

      await this.$store.dispatch("usersStore/read", id);
      let user = this.$store.getters["usersStore/getUser"];

      /* Store active user */
      window.localStorage.setItem("activeUser", JSON.stringify(user));
      this.$store.dispatch("usersStore/setActiveUser", user);
      let project = this.$store.getters["usersStore/getActiveProject"];

      /*Clear and remove active project */
      //window.localStorage.removeItem('activeProject');
      //await this.$store.dispatch('usersStore/clearActiveProject');

      /* Grab participation based on active filters */
      let data = {
        id_user: user.id,
        id_project: project.id,
        code: "",
        flag_user_type: userType,
        flag_status: "",
      };
      await this.$store.dispatch("participationStore/fetch", data);
      let participation = this.$store.getters[
        "participationStore/getParticipation"
      ];

      let participationList = this.$_uniqueParticipationList(
        participation.data
      );

      if (participationList.length > 1) {
        this.$router.push({ name: "projectSelector" });
      } else {
        userType = participation.data[0].flag_user_type;
        /* Store active user type*/
        window.localStorage.setItem("activeUserType", JSON.stringify(userType));
        await this.$store.dispatch("usersStore/setActiveUserType", userType);
        let data = {
          user_type: userType,
        };
        await this.$store.dispatch("navigationStore/fetch", data);
        /* Redirect user to dashboard */
        this.$router.push({ name: "dashboard" });
      }
    },

    async openDetail(id) {
      this.$store.dispatch("progressStore/set", true);

      await this.$store.dispatch("usersStore/read", id);

      this.$router.push({ name: "userDetail", params: { id: id } });
    },

    openChangePassword(id_user) {
      let data = {
        id_user: id_user,
      };
      this.$store.dispatch("usersStore/openPassword", data);
    },

    async openEmail(id) {
      let authUser = this.$store.getters["usersStore/getAuthUser"];
      await this.$store.dispatch("usersStore/read", id);
      let toUser = this.$store.getters["usersStore/getUser"];
      let data = {
        mode: 2,
        section: 2,
        id_user: id,
        to: toUser.email,
        cc: authUser.email,
        subject: "",
        body: "",
        from: authUser.name,
      };
      this.$store.dispatch("emailsStore/openEmail", data);
    },

    async deleteEntry(id) {
      await this.$store.dispatch("usersStore/read", id);
      this.deleteDialog = true;
    },

    async deleteRecord() {
      const record = this.$store.getters["usersStore/getUser"];

      const data = {
        id: record.id,
      };

      await this.$store.dispatch("usersStore/delete", data);
      this.deleteDialog = false;
    },

    cancelDelete() {
      this.deleteDialog = false;
    },

    showFiltered() {
      if (
        this.id ||
        this.name_first ||
        this.name_last ||
        this.email ||
        this.flag_user_type ||
        this.flag_contact_pi ||
        this.flag_status
      ) {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
